import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {HttpClient} from '@angular/common/http'
import {map, Observable, tap} from 'rxjs'
import {unix} from 'moment'

import {environment} from '@env'

import {AuthTokens} from '@core/schemes/auth'
import {UserService} from '@core/services/http/user.service'
import {ACCESS_TOKEN, ACCESS_TOKEN_EXPIRE_DATE, REFRESH_TOKEN} from '@core/constants/local-storage'
import {EduYearService} from './edu-year.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    private eduYearService: EduYearService,
  ) {
  }

  login(payload: any): void {
    this.http.post<AuthTokens>(`${environment.api_v1_url}/identity/auth/token/`, payload).subscribe({
      next: tokens => {
        this.setAuthTokens(tokens)
        this.eduYearService.listYears()
        this.eduYearService.getCurrentYearWithPeriod()
        this.router.navigateByUrl('/')
      },
    })
  }

  setAuthTokens(tokens: AuthTokens): void {
    localStorage.setItem(ACCESS_TOKEN, tokens.access)
    localStorage.setItem(REFRESH_TOKEN, tokens.refresh)

    const decoded = JSON.parse(window.atob(tokens.access.split('.')[1]))
    localStorage.setItem(ACCESS_TOKEN_EXPIRE_DATE, unix(decoded.exp).toISOString())
  }

  refreshTokens(): Observable<AuthTokens> {
    return this.http.post<AuthTokens>(`${environment.api_v1_url}/identity/auth/token/refresh/`, {refresh: this.refreshToken}).pipe(
      tap(tokens => {
        this.setAuthTokens(tokens)
      }),
    )
  }

  logout(): void {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
    localStorage.removeItem(ACCESS_TOKEN_EXPIRE_DATE)
    this.userService.clearState()
    this.router.navigateByUrl('/auth')
  }

  get accessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN)
  }

  get accessTokenExpireDate(): string {
    return localStorage.getItem(ACCESS_TOKEN_EXPIRE_DATE)
  }

  get refreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN)
  }

  get isAuthenticated(): boolean {
    return !!(this.accessToken && this.refreshToken && this.accessTokenExpireDate)
  }

  get hasUserData$(): Observable<boolean> {
    return this.userService.user$.pipe(map(user => this.isAuthenticated && !!user))
  }
}
